import { useState, FormEvent, useEffect, useCallback } from 'react';
import './Contact.scss';
import { developerProfile, DISABLED_MOBILE_MENU_AT_WIDTH } from '../../data/home';
import emailjs from 'emailjs-com';
import emailConfig from '../../data/email-config';
import Spinner from '../../assets/images/spinner.gif';
import { emailSenderInitialData, formDataInitilData, selectOptions } from '../../data/contact';
import { EmailResponse, FormData } from '../../types/contact';
import PropTypes from 'prop-types';

const Contact = ({title, description} : {title: string, description: string}) => {

    const [btnStatus, setBtnStatus] = useState<boolean>(false);

    const [emailSender, setEmailSender] = useState<EmailResponse>(emailSenderInitialData);

    const [formData, setFormData] = useState<FormData>(formDataInitilData);

    const isValidForm = useCallback((): boolean => {
        const { name, email, reason, message } = formData;

        if (name === "" || email === "" || reason === "" || message === "") {
            return false
        }
        return true

    }, [formData]);

    useEffect(() => {
        if (isValidForm()) {
            setBtnStatus(true)
        } else {
            setBtnStatus(false)
        }

    }, [isValidForm])

    const clearMsg = (clearInput: boolean = true) => {
        if(clearInput) {
            setFormData(formDataInitilData);
        }

        setTimeout(() => {
            setEmailSender(emailSenderInitialData);
        }, 5000);
    }

    const sendEmail = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setEmailSender({
            msg: null,
            success: false,
            loader: true
        })

        if (!isValidForm()) {
            setEmailSender({
                msg: "Remplir tous les champs svp!",
                success: false,
                loader: false
            })
            return;
        }

        //e.currentTarget

        emailjs.send(
            emailConfig.serviceID,
            emailConfig.templateID,
            formData,
            emailConfig.publicKey
        ).then((result) => {
            setEmailSender({
                msg: "Votre message a été envoyé",
                success: true,
                loader: false
            });
            
            clearMsg();
        }, (error) => {
            console.log(error.text)
            setEmailSender({
                msg: "Désolé ! votre message n'a pas pu être envoyé.",
                success: false,
                loader: false
            });
            clearMsg(false);
        })
    }

    const btnBlock = window.innerWidth < DISABLED_MOBILE_MENU_AT_WIDTH && "btn-block";

    const errorMsg = (!emailSender.success && emailSender.msg !== null) &&
        <span className='email-sender-msg msg-success'>{emailSender.msg}</span>;

    const successMsg = (emailSender.success && emailSender.msg !== null) &&
        <span className='email-sender-msg msg-error'>{emailSender.msg}</span>;

    const loading = emailSender.loader && <img className='spinner' src={Spinner} alt="spinner" />;

    const submitFormBtn = emailSender.loader ?
        <button className={'button secondary-btn btn-send ' + btnBlock} disabled>Envoyer {loading}</button>
        : <button className={'button secondary-btn btn-send ' + btnBlock} disabled={!btnStatus}>Envoyer {loading}</button>;

    //Vous avez l'idée, j'ai les compétences. <br /> Qu'est-ce qu'on attend?

    const { name, email, reason, message } = formData;

    return (
        <section id={title} className='contact'>
            <div className="container">
                <h2 className='section-title' data-title={title}>{title}</h2>
                <p className='section-description'>{description}</p>

                <div className='contact-content'>
                    <div className="contact-illustration"></div>
                    <form className='contact-form' action="post" onSubmit={sendEmail}>
                        <p className='form-row' style={{ textAlign: 'center' }}>
                            Tous les champs sont obligatoires
                        </p>
                        <div className='form-row'>
                            <label htmlFor="name">Nom *</label>
                            <input type="text" id='name' name='name' value={name} onChange={(e: any) => setFormData({ ...formData, name: e.target.value })} placeholder='Nom et prénom' />
                        </div>
                        <div className='form-row'>
                            <label htmlFor="email">Email *</label>
                            <input type="email" id='email' name='email' value={email} onChange={(e: any) => setFormData({ ...formData, email: e.target.value })} placeholder='Email' />
                        </div>
                        <div className='form-row'>
                            <label htmlFor="reason">Motif *</label>
                            <select id='reason' name='reason' value={reason} onChange={(e: any) => setFormData({ ...formData, reason: e.target.value })}>
                                <option value="">-- Choisir un motif --</option>
                                {selectOptions.map((val, index) => (<option key={index} value={val}>{val}</option>))}
                            </select>
                        </div>
                        <div className='form-row'>
                            <label>Message *</label>
                            <span style={{ marginTop: '6px' }}></span>
                            <textarea rows={15} id='message' name='message' placeholder='Message' value={message} onChange={(e: any) => setFormData({ ...formData, message: e.target.value })}></textarea>
                        </div>

                        {submitFormBtn}
                        {successMsg}
                        {errorMsg}
                    </form>
                </div>

                <div className='contact-infos'>
                    <a href={developerProfile.linkedin} target='_blank' rel='noreferrer' className='contact-item'>
                        <i className="fa-brands fa-linkedin-in"></i>

                        <h5>Linkedin</h5>

                    </a>

                    <a href={'mailto:' + developerProfile.email} target='_blank' rel='noreferrer' className='contact-item'>
                        <i className="fa-solid fa-envelope"></i>

                        <h5>Email</h5>

                    </a>

                    {/* <a href={developerProfile.telegram} target='_blank' rel='noreferrer' className='contact-item'>
                        <i className="fa-brands fa-telegram"></i>

                        <h5>Telegram</h5>
                    </a> */}
                </div>
            </div>
        </section>
    );
}

Contact.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}

export default Contact;