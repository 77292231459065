import './Footer.scss';

const Footer = () => {

    const date = new Date();

    return (
        <section className='footer'>
            <div className="container">
                &copy; {date.getFullYear()} <span>D'Avila Assoko</span> tous droits réservés.
            </div>
        </section>
    );
}
 
export default Footer;