import { MenuItem } from "../types/home.js";

const DISABLED_MOBILE_MENU_AT_WIDTH = 992;

const menuLinks: Array<MenuItem> = [
  {
    title: "home",
    description: null,
  },
  {
    title: "services",
    description: "Faites-vous accompagner sur vos projets",
  },
  {
    title: "portfolio",
    description: "Découvrez mes récents projets",
  },
  {
    title: "technologies",
    description: "Quelques technologies que j'utilise",
  },
  {
    title: "contact",
    description: "Laissez un message",
  },
];

const smoothConfig = {
  spy: false,
  smooth: true,
  offset: -100,
  duration: 700,
};

const developerProfile = {
  name: "D'Avila Assoko",
  email: "contact@davilaassoko.com",
  jobtitle: "Développeur Web & Mobile",
  linkedin: "https://www.linkedin.com/in/ange-d-avila-assoko-9b001165",
  telegram: "https://t.me/davilaassoko",
};

export {
  DISABLED_MOBILE_MENU_AT_WIDTH,
  menuLinks,
  smoothConfig,
  developerProfile,
};
