import WebDeveloper from '../assets/images/web-developer.svg';
import MobileDeveloper from '../assets/images/mobile-development.svg';
import DigitalMarketing from '../assets/images/marketing.svg';
import { ServiceItem } from '../types/service';

export const servicesData: Array<ServiceItem> = [
    {
        image: WebDeveloper,
        title: "Développement Web",
        description: "Création de site internet et d'application web"
    },
    {
        image: MobileDeveloper,
        title: "Développement Mobile",
        description: "Création d'application mobile ios et android"
    },
    // {
    //     image: DigitalMarketing,
    //     title: "Marketing digital",
    //     description: "Gestion de page facebook et instagram"
    // },
]
