import { SkillItem } from "../types/skill.js";
import Html from "../assets/images/icons/html-5.svg";
import Css from "../assets/images/icons/css-3.svg";
import Php from "../assets/images/icons/php.svg";
import Js from "../assets/images/icons/js.svg";
import Jquery from "../assets/images/icons/jquery.svg";
import ReactImg from "../assets/images/icons/reactjs2.png";
import NextImg from "../assets/images/icons/nextjs.svg";
import ReactNative from "../assets/images/icons/react-native.svg";
import Python from "../assets/images/icons/python.svg";
import Dart from "../assets/images/icons/dart.svg";
import CSharp from "../assets/images/icons/c-sharp.svg";
import Flutter from "../assets/images/icons/flutter.svg";
import Symfony from "../assets/images/icons/symfony.svg";
import Django from "../assets/images/icons/django.svg";
import Nodejs from "../assets/images/icons/node-js.svg";
import Sql from "../assets/images/icons/sql-database.png";
import Aws from "../assets/images/icons/aws.svg";
import Azure from "../assets/images/icons/azure.svg";
import AzureDevops from "../assets/images/icons/azure-devops.svg";
import Wordpress from "../assets/images/icons/wordpress.svg";
import Git from "../assets/images/icons/git.svg";
import AdobePhotoshop from "../assets/images/icons/adobe-photoshop.svg";
import AdobeXd from "../assets/images/icons/adobe-xd.svg";

export const skillData: Array<SkillItem> = [
  {
    title: "Html",
    percent: 90,
    icon: Html,
    important: true,
  },
  {
    title: "Css",
    percent: 90,
    icon: Css,
    important: true,
  },
  {
    title: "Php",
    percent: 85,
    icon: Php,
    important: true,
  },
  {
    title: "Javascript",
    percent: 80,
    icon: Js,
    important: true,
  },
  {
    title: "Node Js",
    percent: 75,
    icon: Nodejs,
    important: true,
  },
  {
    title: "Python",
    percent: 85,
    icon: Python,
    important: true,
  },
  {
    title: "Jquery",
    percent: 75,
    icon: Jquery,
    important: false,
  },
  {
    title: "React Js",
    percent: 80,
    icon: ReactImg,
    important: true,
  },
  {
    title: "Next Js",
    percent: 60,
    icon: NextImg,
    important: true,
  },
  {
    title: "React native",
    percent: 70,
    icon: ReactNative,
    important: true,
  },
  {
    title: "Dart",
    percent: 85,
    icon: Dart,
    important: false,
  },
  {
    title: "C#",
    percent: 75,
    icon: CSharp,
    important: false,
  },
  {
    title: "Flutter",
    percent: 80,
    icon: Flutter,
    important: true,
  },
  {
    title: "Symfony",
    percent: 80,
    icon: Symfony,
    important: true,
  },
  {
    title: "Django",
    percent: 75,
    icon: Django,
    important: true,
  },
  {
    title: "Sql",
    percent: 70,
    icon: Sql,
    important: true,
  },
  {
    title: "Merise & uml",
    percent: 75,
    important: false,
  },
  {
    title: "Aws",
    percent: 60,
    icon: Aws,
    important: true,
  },
  {
    title: "Azure",
    percent: 60,
    icon: Azure,
    important: true,
  },
  {
    title: "Azure devops",
    percent: 65,
    icon: AzureDevops,
    important: true,
  },
  {
    title: "Wordpress",
    percent: 80,
    icon: Wordpress,
    important: true,
  },
  {
    title: "Git",
    percent: 85,
    icon: Git,
    important: true,
  },
  {
    title: "Photoshop",
    percent: 65,
    icon: AdobePhotoshop,
    important: true,
  },
  {
    title: "Adobe xd",
    percent: 60,
    icon: AdobeXd,
    important: true,
  },
];
