import React, { useState, useEffect, useCallback } from 'react';
import './Header.scss';
import { menuLinks, smoothConfig, developerProfile, DISABLED_MOBILE_MENU_AT_WIDTH } from '../../data/home';
import { Link } from 'react-scroll';

const Header = () => {
    const [menuToggle, setMenuToggle] = useState<boolean>(false);

    const toggleMenu = () => {
        var menuNewState = !menuToggle
        if(menuNewState) {
            setMenuBackgroundColor('#0B132B')
            setHeaderHeight('285px')
        } else {
            //setMenuBackgroundColor('transparent')
            setHeaderHeight('55px')
        }

        setMenuToggle(menuNewState);

    }

    const [menuBackgroundColor, setMenuBackgroundColor] = useState<string>('transparent');

    const [headerHeight, setHeaderHeight] = useState<string>('55px');

    const manageBackgroundColor = useCallback(() => {
        let windowHeight = window.innerHeight;
        let scrollY = window.scrollY;

        let showColor = scrollY - (windowHeight / 8) > 0;

        if (showColor) {
            setMenuBackgroundColor('#0B132B')
            setHeaderHeight('auto')
        } else {
            if (window.innerWidth >= DISABLED_MOBILE_MENU_AT_WIDTH) {
                setMenuBackgroundColor('transparent')
                setHeaderHeight('auto')
            }
        }
    }, []);

    useEffect(() => {
        manageBackgroundColor();

        window.addEventListener('scroll', () => {
            manageBackgroundColor();
        })

        // window.addEventListener('resize', () => {
        //     manageBackgroundColor();
        // })
    }, [manageBackgroundColor]);


    let menuDisplay = menuToggle ? { display: 'block' } : { display: 'none' };
    let mobileMenuIcon = menuToggle ? 'fa-xmark' : 'fa-bars';

    if (window.innerWidth >= DISABLED_MOBILE_MENU_AT_WIDTH) {
        menuDisplay = { display: 'inherit' };
    }

    return (
        <section id={menuLinks[0].title} className='header'>
            <header style={{ backgroundColor: menuBackgroundColor, height: headerHeight }}>
                <div className="container">
                    <nav className='navbar'>
                        <Link className='logo' to={menuLinks[0].title} {...smoothConfig}>da</Link>

                        {/* <ul style={menuDisplay}> */}
                        <ul style={menuDisplay}>
                            {/* <li><Link activeClass="active" to={menuLinks[0]} {...smoothConfig} onClick={toggleMenu}><i className="fa-solid fa-house"></i></Link></li> */}
                            <li><Link to={menuLinks[1].title} {...smoothConfig} onClick={toggleMenu}>{menuLinks[1].title}</Link></li>
                            {/* <li><Link to={menuLinks[2].title} {...smoothConfig} onClick={toggleMenu}>{menuLinks[2].title}</Link></li> */}
                            <li><Link to={menuLinks[3].title} {...smoothConfig} onClick={toggleMenu}>{menuLinks[3].title}</Link></li>
                            <li><Link to={menuLinks[4].title} {...smoothConfig} onClick={toggleMenu}>{menuLinks[4].title}</Link></li>
                        </ul>

                        <button className='toggle-menu-btn' onClick={toggleMenu}><i className={'fa-solid ' + mobileMenuIcon}></i></button>
                    </nav>
                </div>
            </header>
            <div className='developer-description'>
                <p className='hello'>
                    Hello!
                </p>
                <h1 className='introducing'>
                    <span className='name'>{developerProfile.name}</span> <span className='jobtitle'>{developerProfile.jobtitle}</span>
                </h1>

                <div className='actions-btn'>
                    <Link to={menuLinks[4].title} {...smoothConfig} className='button collabo-btn lg'>Contactez-moi!</Link>
                    {/* <Link to={menuLinks[1]} {...smoothConfig} className='button service-btn lg'>Mes services</Link> */}
                </div>
            </div>
        </section>
    );
}

export default Header;