import './Collaboration.scss';
import { Link } from 'react-scroll';
import {  menuLinks, smoothConfig } from '../../data/home';
import PropTypes from 'prop-types';

const Collaboration = ({description} : {description: string}) => {
    return (
        <section className='collaboration'>
            <div className="container collaboration-content">
                <h4 className=''>{description}</h4>
                <Link to={menuLinks[4].title} {...smoothConfig} className='button collabo-btn lg'>Demander un dévis</Link>
            </div>
        </section>
    );
}


Collaboration.propTypes = {
    description: PropTypes.string.isRequired,
}
 
export default Collaboration;