import './Skills.scss';

import { skillData } from '../../data/skillData';

import PropTypes from 'prop-types';

const Skills = ({title, description, iconMode} : {title: string, description: string, iconMode: boolean}) => {

    const skillsItems = skillData.map( (item, index) => {
        let percent = item.percent + '%';

        const content = !iconMode ?  
        <div key={index} className='skill-item'>
            <div className='skill-item-details' style={{ width: percent }}>
                <span>{item.title}</span>
                <span>{percent}</span>
            </div>
            <div className="progress" style={{ width: percent }}></div>
        </div> : item.icon &&  <div key={index} className="skill-item-img">
            <img src={item.icon} alt={item.title} />
            <p>{item.title}</p>
            </div>

        if(!item.important) {
            return <></>
        }

        if(iconMode && item.icon === undefined) {
            return <></>
        }

        return content
    });
    return (
        <section id={title} className='skills'>
            <div className="container">
                <h2 className='section-title' data-title={title}>{title}</h2>
                <p className='section-description'>{description}</p>

                <div className={ !iconMode ? 'skills-items' : 'skills-img-items' }>
                    {skillsItems}
                </div>
            </div>
        </section>
    );
}

Skills.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    iconMode: PropTypes.bool.isRequired
}

export default Skills;