import { EmailResponse, FormData } from "../types/contact";

export const emailSenderInitialData: EmailResponse = {
    msg: null,
    success: false,
    loader: false
}

export const formDataInitilData: FormData = {
    name: "",
    email: "",
    reason: "",
    message: ""
}

export const selectOptions = [
    "Création d'un site internet",
    "Création d'une app web",
    "Création d'une app mobile",
    "Création d'une app web & mobile",
    "Communication digitale FB et/ou Insta",
    "Autre : à préciser dans le message"
]