import BackToTop from '../../components/back-to-top/BackToTop';
import Collaboration from '../../components/collaboration/Collaboration';
import Contact from '../../components/contact/Contact';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Portfolio from '../../components/portfolio/Portfolio';
import Services from '../../components/services/Services';
import Skills from '../../components/skills/Skills';
import { menuLinks } from '../../data/home';
import './Home.scss';

const Home = () => {
    return ( 
        <>
            <Header />
            <Services title={menuLinks[1].title} description={menuLinks[1].description!} />
            <Collaboration description="Décrivez votre projet en quelques mots et obtenez un dévis" />
            {/* <Portfolio title={menuLinks[2].title} description={menuLinks[2].description!} /> */}
            <Skills title={menuLinks[3].title} description={menuLinks[3].description!} iconMode={true} />
            <Contact title={menuLinks[4].title} description={menuLinks[4].description!} />
            <Footer />
            <BackToTop />
        </>
    );
}
 
export default Home;