import React, { useState, useEffect } from 'react';
import './BackToTop.scss';
import { Link } from 'react-scroll';
import { menuLinks, smoothConfig } from '../../data/home';


const BackToTop = () => {

    const [backToTop, setBackToTop] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            let windowHeight = window.innerHeight;
            let scrollY = window.scrollY;


            let showBtn = scrollY - (windowHeight * 1.2) > 0;

            if (showBtn) {
                setBackToTop(true)
            } else {
                setBackToTop(false)
            }

        })
    }, []);

    const display = backToTop === true ?  'back-to-top-visible' :  'back-to-top-hidden' ;

    return (
        <Link className={'back-to-top ' + display} to={menuLinks[0].title} {...smoothConfig} title='back to top'>
            <i className="fa-solid fa-arrow-up-long"></i>
        </Link>
    );
}

export default BackToTop;