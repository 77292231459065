import React, { useEffect } from 'react';
import isDev from '../../helpers/isDev';
import Home from '../home/Home';
import './App.scss';

function App() {
  // useEffect(() => {
  //     if(!isDev()) {
  //       const body = document.getElementsByTagName('body');

  //       if(body !== null) {
  //         body[0].setAttribute('oncontextmenu', "return false")
  //       }

  //       document.onkeydown = function(e) {

  //         if(e.key === '123' || e.key === 'F12') {
  //            return false;
  //         }
  //         if(e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'i')) {
  //            return false;
  //         }
  //         if(e.ctrlKey && e.shiftKey && (e.key === 'C' || e.key === 'c')) {
  //            return false;
  //         }
  //         if(e.ctrlKey && e.shiftKey && (e.key === 'J' || e.key === 'j')) {
  //            return false;
  //         }
  //         if(e.ctrlKey && (e.key === 'U' || e.key === 'u')) {
  //            return false;
  //         }
  //       }

  //     }
  //   }, [])

  return (
      <Home />
  );
}

export default App;
