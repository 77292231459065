import './Services.scss';
import { menuLinks } from '../../data/home';
import { servicesData } from '../../data/serviceData';
import PropTypes from 'prop-types';

const Services = ({title, description} : {title: string, description: string}) => {

    const serviceItems = servicesData.map(item => 
        (
            <div key={item.image} className="service-item">
                <img src={item.image} alt="service-illustration" />

                <h3>{item.title}</h3>

                <hr />

                <p>{item.description}</p>
            </div>
        )
    )

    return (
        <section id={title} className='services'>
            <div className="container">
                <h2 className='section-title' data-title={title}>{title}</h2>
                <p className='section-description'>{description}</p>
                <div className='services-items'>
                    {serviceItems}
                </div>
            </div>
        </section>
    );
}

Services.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}

export default Services;